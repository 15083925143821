/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 18, 2020 */

@font-face {
    font-family: 'antoniolight';
    src: url('../../fonts/antonio/antonio-light-webfont.woff2') format('woff2'), url('../../fonts/antonio/antonio-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'antonioregular';
    src: url('../../fonts/antonio/antonio-regular-webfont.woff2') format('woff2'), url('../../fonts/antonio/antonio-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'antoniobold';
    src: url('../../fonts/antonio/antonio-bold-webfont.woff2') format('woff2'), url('../../fonts/antonio/antonio-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
li {
    color: $light-gray;
}

h1,
h2,
h3 {
    font-family: 'antoniobold';
}

h1 {
    font-size: 3.2em;
}

h2 {
    font-size: 2.4em;
}

h3 {
    font-size: 2em;
}

h4,
h5,
h6 {
    font-family: 'antonioregular';
}

h4 {
    font-size: 1.6em;
}

h5,
h6,
li {
    font-size: 1.2em;
}

p,
label,
span,
a,
li {
    font-family: 'antoniolight';
    font-size: 1em;
    line-height: normal;
}