main {
    aside.others {
        width: 25%;
        vertical-align: top;
        >div {
            position: relative;
            .bg-other {
                width: 100%;
            }
            .others-txt {
                padding: 1rem;
                margin-top: 10.5rem;
                border-bottom: $light-gray solid 0.05rem;
                .others-title {
                    margin-bottom: 1rem;
                }
                .others-list {
                    .other {
                        margin-bottom: .8rem;
                        &:hover {
                            opacity: .6;
                        }
                    }
                }
            }
            .download-cover-letter {
                position: absolute;
                top: 12rem;
                right: 1rem;
                img {
                    opacity: .84;
                    width: 150px;
                    transition: all 0.5s ease;
                    -webkit-animation: spin 8s infinite linear;
                    @keyframes spin {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
            }
        }
    }
}