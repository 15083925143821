$dark-gray: #080808;
$mid-gray: #121212;
$light-gray: #F4F4F4;

$poly-red: #C81414;
$alca-yellow: #F9EC03;
$glob-green: #86996c;
$alien-gray: #9E9E9E;

$dg: #d8d8d8;
$bc: #619AA9;
$sc: #EC5405;
$ig: #DD2A7B;

$precious-plastic: #B7D8E5;

//RELEASES
.poly-red{
    &:hover{
        color: $poly-red;
    }
}
.alca-yellow{
    &:hover{
        color: $alca-yellow;
    }
}
.glob-green{
    &:hover{
        color: $glob-green;
    }
}
.alien-gray{
    &:hover{
        color: $alien-gray;
    }
}

//RRSS
.bc{
    &:hover{
        color: $bc;
    }
}
.dg{
    &:hover{
        color: $dg;
    }
}
.sc{
    &:hover{
        color: $sc;
    }
}
.ig{
    &:hover{
        color: $ig;
    }
}

//OTHERS
.precious-plastic{
    &:hover{
        color: $precious-plastic;
    }
}