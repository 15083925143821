html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

.poly-red:hover {
  color: #C81414;
}

.alca-yellow:hover {
  color: #F9EC03;
}

.glob-green:hover {
  color: #86996c;
}

.alien-gray:hover {
  color: #9E9E9E;
}

.bc:hover {
  color: #619AA9;
}

.dg:hover {
  color: #d8d8d8;
}

.sc:hover {
  color: #EC5405;
}

.ig:hover {
  color: #DD2A7B;
}

.precious-plastic:hover {
  color: #B7D8E5;
}

body {
  width: 96%;
  display: table;
  background-color: #121212;
  margin: 16px auto;
  max-width: 1120px;
  border: #F4F4F4 solid 0.05rem;
}

.bg {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url("http://assets.iceable.com/img/noise-transparent.png") repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 0.8;
  visibility: visible;
  z-index: -1;
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 18, 2020 */
@font-face {
  font-family: "antoniolight";
  src: url("../../fonts/antonio/antonio-light-webfont.woff2") format("woff2"), url("../../fonts/antonio/antonio-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "antonioregular";
  src: url("../../fonts/antonio/antonio-regular-webfont.woff2") format("woff2"), url("../../fonts/antonio/antonio-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "antoniobold";
  src: url("../../fonts/antonio/antonio-bold-webfont.woff2") format("woff2"), url("../../fonts/antonio/antonio-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
li {
  color: #F4F4F4;
}

h1,
h2,
h3 {
  font-family: "antoniobold";
}

h1 {
  font-size: 3.2em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 2em;
}

h4,
h5,
h6 {
  font-family: "antonioregular";
}

h4 {
  font-size: 1.6em;
}

h5,
h6,
li {
  font-size: 1.2em;
}

p,
label,
span,
a,
li {
  font-family: "antoniolight";
  font-size: 1em;
  line-height: normal;
}

header .section-1,
header .section-2 {
  display: flex;
}
header .section-1 {
  border-bottom: #F4F4F4 solid 0.05rem;
}
header .section-1 h1,
header .section-1 p,
header .section-1 .chip-pm-link {
  display: inline-block;
}
header .section-1 h1 {
  padding: 1rem;
  width: 25%;
  border-right: #F4F4F4 solid 0.05rem;
}
header .section-1 p {
  width: 65%;
  padding: 1rem;
}
header .section-1 .chip-pm-link {
  width: 10%;
  padding: 1.2rem 1rem;
  text-align: center;
  border-left: #F4F4F4 solid 0.05rem;
}
header .section-1 .chip-pm-link img {
  width: 76%;
}
header .section-1 .chip-pm-link img:hover {
  opacity: 0.6;
}
header .section-2 {
  width: 100%;
  border-bottom: #F4F4F4 solid 0.05rem;
}
header .section-2 h2.releases-title,
header .section-2 .rrss {
  display: inline-block;
}
header .section-2 h2.releases-title {
  width: 75%;
  padding: 1rem;
  border-right: #F4F4F4 solid 0.05rem;
}
header .section-2 .contact {
  width: 25%;
  padding: 1rem;
  text-align: right;
}

main section.releases,
main aside.others {
  display: inline-block;
}
main section.releases {
  width: 75%;
}
main section.releases .release {
  width: 27%;
  display: table-cell;
  border-right: #F4F4F4 solid 0.05rem;
  border-bottom: #F4F4F4 solid 0.05rem;
}
main section.releases .release img {
  width: 100%;
  border-bottom: #F4F4F4 solid 0.05rem;
  vertical-align: top;
}
main section.releases .release .release-txt {
  padding: 1rem;
}
main section.releases .release .release-txt .release-title,
main section.releases .release .release-txt .label,
main section.releases .release .release-txt .date {
  margin-bottom: 1rem;
  line-height: 1.2em;
}
main section.releases .release .release-txt .release-title {
  line-height: 2.4rem;
}
main section.releases .release .release-txt .cat,
main section.releases .release .release-txt .date {
  text-align: right;
}
main section.releases .release .release-txt .cat,
main section.releases .release .release-txt .tracklist,
main section.releases .release .release-txt li {
  margin-bottom: 0.4rem;
}
main section.releases .release:nth-last-child(2), main section.releases .release:nth-last-child(1) {
  display: inline-block;
  width: 50%;
  border-bottom: 0;
}
main section.releases .release:nth-last-child(2) img,
main section.releases .release:nth-last-child(2) .release-txt, main section.releases .release:nth-last-child(1) img,
main section.releases .release:nth-last-child(1) .release-txt {
  display: inline-block;
}
main section.releases .release:nth-last-child(2) img, main section.releases .release:nth-last-child(1) img {
  border-bottom: 0;
  margin-bottom: -0.4rem;
  border-right: #F4F4F4 solid 0.05rem;
}
main section.releases .release:nth-last-child(2) .release-txt, main section.releases .release:nth-last-child(1) .release-txt {
  height: 20rem;
  width: 100%;
  padding: 1rem;
  vertical-align: top;
}
main .support {
  border-top: #F4F4F4 solid 0.05rem;
  border-bottom: #F4F4F4 solid 0.05rem;
}
main .support .support-container {
  display: flex;
}
main .support .support-container .thanks-container,
main .support .support-container .damalga-container {
  width: 50%;
  padding: 1rem;
}
main .support .support-container .thanks-container .damalga-txt,
main .support .support-container .damalga-container .damalga-txt {
  text-align: right;
}
main .support .support-container .thanks-container {
  border-right: #F4F4F4 solid 0.05rem;
}

footer {
  width: 100%;
  display: flex;
}
footer .support,
footer .contact,
footer .rrss {
  padding: 2rem;
}
footer .support,
footer .contact {
  display: inline-block;
}
footer .support {
  width: 50%;
  border-right: #F4F4F4 solid 0.05rem;
}
footer .rrss {
  width: 100%;
  text-align: right;
}
footer .rrss .rrss-list .rs {
  display: inline;
  margin-left: 1rem;
}
footer .rrss .rrss-list .rs:first-child {
  margin-left: 0;
}

main aside.others {
  width: 25%;
  vertical-align: top;
}
main aside.others > div {
  position: relative;
}
main aside.others > div .bg-other {
  width: 100%;
}
main aside.others > div .others-txt {
  padding: 1rem;
  margin-top: 10.5rem;
  border-bottom: #F4F4F4 solid 0.05rem;
}
main aside.others > div .others-txt .others-title {
  margin-bottom: 1rem;
}
main aside.others > div .others-txt .others-list .other {
  margin-bottom: 0.8rem;
}
main aside.others > div .others-txt .others-list .other:hover {
  opacity: 0.6;
}
main aside.others > div .download-cover-letter {
  position: absolute;
  top: 12rem;
  right: 1rem;
}
main aside.others > div .download-cover-letter img {
  opacity: 0.84;
  width: 150px;
  transition: all 0.5s ease;
  -webkit-animation: spin 8s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-3.images {
  padding: 1rem;
}

@media (max-width: 968px) {
  header .section-1 p {
    width: 60%;
    padding: 0.6rem 1rem;
  }
  header .section-1 .chip-pm-link {
    width: 20%;
    vertical-align: bottom;
  }
  header .section-2 h2.releases-title {
    width: 50%;
  }
  header .section-2 .contact {
    width: 50%;
    padding: 0.6rem 1rem;
    display: inline-block;
    vertical-align: bottom;
  }
  main section.releases .release {
    width: 100%;
    display: inline-block;
  }
  main section.releases .release:nth-last-child(2), main section.releases .release:nth-last-child(1) {
    display: block;
    width: 100%;
  }
  main section.releases .release:nth-last-child(2) .release-txt, main section.releases .release:nth-last-child(1) .release-txt {
    height: auto;
  }
  main section.releases .release:nth-last-child(2) img,
  main section.releases .release:nth-last-child(2) .release-txt {
    width: 100%;
  }
  main section.releases .release:nth-last-child(1) {
    flex-direction: column;
  }
}
@media (max-width: 776px) {
  body {
    font-size: larger;
  }
  h1 {
    font-size: 2.4em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.6em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5,
  h6,
  li {
    font-size: 1em;
  }
  p,
  label,
  span,
  a,
  li {
    font-size: 0.9em;
  }
  header .section-1 h1 {
    width: auto;
  }
  main section.releases,
  main aside.others {
    border-top: #F4F4F4 solid 0.05rem;
    width: 100%;
    display: inline-block;
  }
  main section.releases .release .release-txt .release-title {
    line-height: 2.8rem;
  }
  main aside.others > div .download-cover-letter {
    top: 20rem;
  }
  main aside.others > div .others-txt {
    margin-top: 0;
  }
  main .support {
    padding: 0;
  }
  main .support .support-container {
    flex-direction: column;
  }
  main .support .support-container .thanks-container,
  main .support .support-container .damalga-container {
    width: 100%;
    padding: 1rem;
  }
  main .support .support-container .thanks-container {
    border-bottom: #F4F4F4 solid 0.05rem;
    padding-bottom: 1rem;
  }
}
@media (max-width: 624px) {
  header .section-1,
  header .section-2 {
    display: block;
  }
  header .section-1 h1,
  header .section-2 h1 {
    width: 100%;
    border-bottom: #F4F4F4 solid 0.05rem;
  }
  header .section-1 p,
  header .section-2 p {
    width: 80%;
  }
  footer {
    display: block;
  }
  footer .support,
  footer .rrss {
    width: 100%;
    border-right: 0;
  }
  footer .support {
    border-bottom: #F4F4F4 solid 0.05rem;
  }
}
@media (max-width: 440px) {
  header .section-1 p {
    width: 75%;
  }
  header .section-1 .chip-pm-link {
    width: 25%;
  }
  header .section-2 .contact {
    display: inline-block;
  }
}

