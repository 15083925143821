@media (max-width: 968px) {
    header {
        .section-1 {
            p {
                width: 60%;
                padding: .6rem 1rem;
            }
            .chip-pm-link {
                width: 20%;
                vertical-align: bottom; 
            }
        }
        .section-2 {
            h2.releases-title {
                width: 50%;
            }
            .contact {
                width: 50%;
                padding: .6rem 1rem;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
    main {
        section.releases {
            .release {
                width: 100%;
                display: inline-block;
                &:nth-last-child(2),
                &:nth-last-child(1){
                    display: block;
                    width: 100%;
                    .release-txt {
                        height: auto;
                    }
                }
                &:nth-last-child(2) {
                    img,
                    .release-txt {
                        width: 100%;
                    }
                }
                &:nth-last-child(1) {
                    flex-direction: column;
                }
            }
        }
    }
}

@media (max-width: 776px) {
    body{
        font-size: larger;
    }
    h1 {
        font-size: 2.4em;
    }
    h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.6em;
    }
    h4 {
        font-size: 1.2em;
    }
    h5,
    h6,
    li {
        font-size: 1em;
    }
    p,
    label,
    span,
    a,
    li {
        font-size: .9em;
    }
    header{
        .section-1{
            h1{
                width: auto;
            }
        }
    }
    main {
        section.releases,
        aside.others {
            border-top: $light-gray solid 0.05rem;
            width: 100%;
            display: inline-block;
        }
        section.releases {
            .release {
                .release-txt {
                    .release-title {
                        line-height: 2.8rem;
                    }
                }
            }
        }
        aside.others {
            >div {
                .download-cover-letter{
                    top: 20rem;
                }
                .others-txt {
                    margin-top: 0;
                }
            }
        }
        .support{
            padding: 0;
            .support-container{
                flex-direction: column;
                .thanks-container,
                .damalga-container{
                    width: 100%;
                    padding: 1rem;
                }
                .thanks-container{
                    border-bottom: $light-gray solid 0.05rem;
                    padding-bottom: 1rem;
                }
            }
        }
    }
}

@media (max-width: 624px) {
    header {
        .section-1,
        .section-2 {
            display: block;
            h1 {
                width: 100%;
                border-bottom: $light-gray solid .05rem;
            }
            p {
                width: 80%;
            }
        }
    }
    footer {
        display: block;
        .support,
        .rrss {
            width: 100%;
            border-right: 0;
        }
        .support {
            border-bottom: $light-gray solid .05rem;
        }
    }
}

@media (max-width: 440px) {
    header {
        .section-1 {
            p {
                width: 75%;
            }
            .chip-pm-link {
                width: 25%;
            }
        }
        .section-2 {
            .contact {
                display: inline-block;
            }
        }
    }
}